<template>
<div>
  <Row >
    <Col span="24">
      <Table stripe border :columns="columns" :data="list">
        <template slot="no" slot-scope="{index}">{{index + 1}}</template>
      </Table>
    </Col>
  </Row>

  <Row style="margin-top: 15px">
    <Col span="24" style="text-align: center">
      <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
    </Col>
  </Row>
</div>
</template>

<script>
import api from '../../api/xiaochengxu';
export default {
  name: "UserLog",
  data: function(){
    return {
      searchKey: {
        page: 1,
        pagesize: 30
      },
      list: [],
      total: 0,
      columns: [
        {title: '#', slot: 'no', width: 80, align: 'center'},
        {title: '昵称', key: 'user_nick',  width: 180},
        {title: '姓名', key: 'user_real_name', width: 120},
        {title: '时间', key: 'user_log_date', width: 180},
        {title: '动态', key: 'user_log_action'}
      ],
    }
  },
  methods: {
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    getList: function(){
      this.$http.get(api.getLogList, {params : this.searchKey}).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
        } else {
          this.$Message.error(response.data.err);
          console.log(response);
        }
      })
    }
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>

</style>